import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Seo from '../components/seo'

import Hero from '../components/hero'
import ProductPreview from '../components/product-preview'
import Instagram from '../components/instagram'
import FindUsBanner from '../components/find-us-banner'
import Bio from '../components/bio'
import ReceipeBanner from '../components/receipe-banner'

class RootIndex extends React.Component {
  render() {
    let products = get(this, 'props.data.allContentfulProduct.nodes')
    products = products.filter(product => product.slug !== 'cornichon')
    products = products.slice(0, 3);

    return (
      <>
        <Seo title="Découvrez nos produits à base de tofu" description="Un tofu fumé avec soin, à partir de soya biologique québécois. Peu transformé, pratique et savoureux, Tofutofu incarne une cuisine saine et moderne." />
        <Hero />
        <ProductPreview products={products} />
        <FindUsBanner />
        <Instagram />
        <Bio />
        <ReceipeBanner />
      </>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulProduct(limit: 6) {
      nodes {
        title
        slug
        order
        heroImage {
          gatsbyImage(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 424
            height: 321
          )
        }
        description {
          raw
        }
      }
    }
  }
`
