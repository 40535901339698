import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './bio.module.css'

import Marquee from './marquee'

const Bio = () => {

  return (
    <div className={styles.bio}>
      <Container className={styles.bioContainer}>
        <div>
          <h2>L’artisane</h2>
          <h3>Derrière Tofutofu, il y a avant tout une vision gourmande et engagée</h3>
          <hr />
          <div className={styles.bioContent}>
            <StaticImage
              className={styles.portrait}
              alt="Dominique Dupuis"
              src="../../static/images/portrait.png"
              placeholder="blurred"
            />
            <div className={styles.bioContentText}>
              <p className={styles.bioIntro}>
              Habitée par le désir de faciliter l’accès à une alimentation saine et végétale, Dominique, technologue en nutrition, a transformé sa passion en mission. Après plus de 11 ans à enseigner la cuisine végétale, elle fonde Tofutofu
              </p>
              <hr />
              <p className={styles.bioPara}>
              Avec Tofutofu, j'ai réinventé la protéine végétale en alliant tradition et innovation. Nos produits, faits à base de tofu biologique du Québec, sont pensés pour simplifier le quotidien tout en offrant des saveurs accrocheuses et une qualité nutritionnelle exceptionnelle. Peu transformés, savoureux et prêts-à-manger, ils incarnent ma vision d’une alimentation locale, pratique et gourmande.
              </p>
              <p className={styles.bioSignature}>
                Dominique Dupuis
              </p>
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.marquee}>
        <Marquee />
      </div>
    </div>
  )
}

export default Bio
