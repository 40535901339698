export const sliderData = [
  {
    name: "Capitaine",
    bgColor: "#ECC0A4"
  },
  {
    name: "Rôtisserie",
    bgColor: "#F9D6A3"
  },
  {
    name: "Smoke Meat",
    bgColor: "#FFE9CC"
  },
];
