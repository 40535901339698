import React, { useRef, useState } from "react";
import { Link } from 'gatsby'
import { gsap, Power3 } from "gsap";
import * as styles from './hero.module.css'
import { StaticImage } from 'gatsby-plugin-image'

import arrowImg from "../../static/images/arrow.svg";

import { sliderData } from "./SliderData";

const Hero = () => {
  let persos = useRef(null);
  let images = useRef(null);
  let container = useRef(null);
  let flavors = useRef(null);

  const [state, setState] = useState({
    isActive1: true,
    isActive2: false,
    isActive3: false,
  });

  let width = 0;
  try {
      width = window.innerWidth;
  } catch(e) {}

  const duration = 1.6;

  // Persos Image transition
  const persosColorChange = (showTarget, hideTargets) => {
    gsap.to(persos.children[hideTargets[0]], {
      duration,
      css: { opacity: 0 },
      ease: Power3.easeInOut
    });
    gsap.to(persos.children[hideTargets[1]], {
      duration,
      css: { opacity: 0 },
      ease: Power3.easeInOut
    });
    gsap.to(persos.children[showTarget], {
      duration,
      css: { opacity: 1 },
      ease: Power3.easeInOut
    });
  };

  // Main Image transition
  const mainImageUp = (startIndex, endIndex) => {
    gsap.set(images.children[endIndex], {
      css: width > 768 ? { top: `61.5vw` } : { left: `100vw` }
    });
    gsap.to(images.children[endIndex], {
      duration,
      css: width > 768 ? { top: `0vh` } : { left: `0vh` },
      ease: Power3.easeInOut
    });
    console.log(images.children[startIndex])
    gsap.to(images.children[startIndex], {
      duration,
      css: width > 768 ? { top: `-61.5vw` } : { left: `-100vw` },
      ease: Power3.easeInOut
    });
  };

  const mainImageDown = (startIndex, endIndex) => {
    gsap.set(images.children[endIndex], {
      css: width > 768 ? { top: `-61.5vw` } : { left: `-100vw` }
    });
    gsap.to(images.children[endIndex], {
      duration,
      css: width > 768 ? { top: `0vh` } : { left: `0vh` },
      ease: Power3.easeInOut
    });
    gsap.to(images.children[startIndex], {
      duration,
      css: width > 768 ? { top: `61.5vw` } : { left: `100vw` },
      ease: Power3.easeInOut
    });
  };

  // Flavors transition
  const nextFlavorChange = (startIndex, endIndex) => {
    gsap.to(flavors.children[startIndex], {
      duration,
      css: { opacity: 0 },
      ease: Power3.easeInOut
    });
    gsap.to(flavors.children[endIndex], {
      duration,
      css: { opacity: 1 },
      ease: Power3.easeInOut
    });
  };

  // Background Color transition
  const backgroundColorChange = (targetColor) => {
    gsap.to(container, {
      duration,
      css: { "background-color": targetColor },
      ease: Power3.easeInOut
    });
  };

  const slideUp = () => {
    if (images?.children[0].classList.contains("active")) {
      setState({ isActive1: false, isActive2: true, isActive3: false });
      persosColorChange(1, [0, 2]);
      mainImageUp(0, 1);
      backgroundColorChange(sliderData[1].bgColor);
      nextFlavorChange(0, 1);
    } else if (images?.children[1].classList.contains("active")) {
      setState({ isActive1: false, isActive2: false, isActive3: true });
      persosColorChange(2, [0, 1]);
      mainImageUp(1, 2);
      backgroundColorChange(sliderData[2].bgColor);
      nextFlavorChange(1, 2);
    } else if (images?.children[2].classList.contains("active")) {
      setState({ isActive1: true, isActive2: false, isActive3: false });
      persosColorChange(0, [0, 1]);
      mainImageUp(2, 0);
      backgroundColorChange(sliderData[0].bgColor);
      nextFlavorChange(2, 1);
    }
  };

  const slideDown = () => {
    if (images?.children[0].classList.contains("active")) {
      setState({ isActive1: false, isActive2: false, isActive3: true });
      persosColorChange(2, [0, 1]);
      mainImageDown(0, 2);
      backgroundColorChange(sliderData[2].bgColor);
      nextFlavorChange(0, 2);
    } else if (images?.children[1].classList.contains("active")) {
      setState({ isActive1: true, isActive2: false, isActive3: false });
      persosColorChange(0, [2, 1]);
      mainImageDown(1, 0);
      backgroundColorChange(sliderData[0].bgColor);
      nextFlavorChange(1, 0);
    } else if (images?.children[2].classList.contains("active")) {
      setState({ isActive1: false, isActive2: true, isActive3: false });
      persosColorChange(2, [1, 0]);
      mainImageDown(2, 1);
      backgroundColorChange(sliderData[1].bgColor);
      nextFlavorChange(2, 1);
    }
  };

  try {setTimeout(width > 768 ? slideDown : slideUp, 5000)} catch (e) {}

  return (
    <div>
      <div ref={el => (container = el)} className={styles.sliderContainer}>
        <div onClick={slideUp} className={styles.arrows}>
          <img src={arrowImg} alt="left arrow" />
        </div>

        <div>
          <div ref={el => (flavors = el)}>
            <h1 className={state.isActive1 ? `${styles.flavor} active` : `${styles.flavor}`}>{sliderData[0].name}</h1>
            <h1 className={state.isActive2 ? `${styles.flavor} ${styles.flavor2} active` : `${styles.flavor} ${styles.flavor2}`}>{sliderData[1].name}</h1>
            <h1 className={state.isActive3 ? `${styles.flavor} ${styles.flavor3} active` : `${styles.flavor} ${styles.flavor3}`}>{sliderData[2].name}</h1>
          </div>

          <div className={styles.text}>
            <div className={styles.logo}>
              <svg xmlns="http://www.w3.org/2000/svg" width="279.137" height="161.266" viewBox="0 0 279.137 161.266">
                <g id="Group_24" data-name="Group 24" transform="translate(-12.683 -12.461)">
                  <g id="Group_1" data-name="Group 1" transform="translate(12.683 12.461)">
                    <path id="Path_1" data-name="Path 1" d="M37.247,57.149q0-6.166-.1-12.334H12.683V12.956h53.34V44.815H41.768q0,6.17-.1,12.334,0,9.46.515,27.751H36.731Q37.247,68.251,37.247,57.149Z" transform="translate(-12.683 -11.724)" fill="#007b56"/>
                    <path id="Path_2" data-name="Path 2" d="M47.962,77.827q-9.15-9.355-9.15-28.059t9.15-28.007q9.146-9.3,27.544-9.3,18.5,0,27.646,9.3T112.3,49.767q0,18.707-9.147,28.059T75.506,87.178Q57.105,87.178,47.962,77.827ZM95.648,54.8q6.681-.508,8.943-1.7a3.656,3.656,0,0,0,2.261-3.339,3.551,3.551,0,0,0-2.261-3.286q-2.263-1.135-8.891-1.646T75.506,44.32q-13.467,0-20.095.515t-8.888,1.646a3.55,3.55,0,0,0-2.263,3.286,3.655,3.655,0,0,0,2.313,3.339q2.312,1.184,8.891,1.7t20.042.515Q88.969,55.317,95.648,54.8Z" transform="translate(26.242 -12.461)" fill="#007b56"/>
                    <path id="Path_3" data-name="Path 3" d="M73.98,73.492q.205-15.622.207-24.357,0-9.15-.207-27.031-.1-4.627-.2-9.147h53.751V43.789H78.708v6.989q28.678,0,42.96-.311v5.039q-14.285-.31-42.96-.311,0,7.813.309,16.139.205,8.84.2,13.567H73.776Q73.877,79.141,73.98,73.492Z" transform="translate(78.33 -11.724)" fill="#007b56"/>
                    <path id="Path_4" data-name="Path 4" d="M112.663,82.689a20.581,20.581,0,0,1-9.351-9.864A33.828,33.828,0,0,1,100.794,58.9q.1-4.317.1-13.567,0-19.831-.413-32.374h5.654q-.515,24.667-.513,28.261a12.872,12.872,0,0,0,1.952,7.454q1.949,2.823,7.4,4.367T130.7,54.579q10.483,0,15.929-1.541t7.4-4.419a13.131,13.131,0,0,0,1.952-7.5q0-11.715-.515-28.159h5.552q-.209,3.906-.309,14.388t-.1,20.66l.1,10.995a34.112,34.112,0,0,1-2.52,14.079,20.17,20.17,0,0,1-9.3,9.712q-6.681,3.649-18.088,3.65T112.663,82.689Z" transform="translate(118.116 -11.724)" fill="#007b56"/>
                    <path id="Path_5" data-name="Path 5" d="M37.247,91.911q0-6.166-.1-12.334H12.683V47.718h53.34V79.577H41.768q0,6.17-.1,12.334,0,9.46.515,27.751H36.731Q37.247,103.013,37.247,91.911Z" transform="translate(-12.683 40.063)" fill="#007b56"/>
                    <path id="Path_6" data-name="Path 6" d="M47.962,112.589q-9.15-9.355-9.15-28.059t9.15-28.007q9.146-9.3,27.544-9.3,18.5,0,27.646,9.3T112.3,84.529q0,18.707-9.147,28.059T75.506,121.94Q57.105,121.94,47.962,112.589ZM95.648,89.564q6.681-.512,8.943-1.7a3.656,3.656,0,0,0,2.261-3.339,3.551,3.551,0,0,0-2.261-3.286Q102.328,80.108,95.7,79.6t-20.194-.515q-13.467,0-20.095.515t-8.888,1.646a3.55,3.55,0,0,0-2.263,3.286,3.655,3.655,0,0,0,2.313,3.339q2.312,1.184,8.891,1.7t20.042.515Q88.969,90.079,95.648,89.564Z" transform="translate(26.242 39.325)" fill="#007b56"/>
                    <path id="Path_7" data-name="Path 7" d="M73.98,108.254q.205-15.622.207-24.357,0-9.15-.207-27.031-.1-4.627-.2-9.147h53.751V78.551H78.708V85.54q28.678,0,42.96-.311v5.037q-14.285-.306-42.96-.309,0,7.813.309,16.139.205,8.84.2,13.567H73.776Q73.877,113.9,73.98,108.254Z" transform="translate(78.33 40.063)" fill="#007b56"/>
                    <path id="Path_8" data-name="Path 8" d="M112.663,117.451a20.575,20.575,0,0,1-9.351-9.867,33.809,33.809,0,0,1-2.517-13.925q.1-4.317.1-13.567,0-19.835-.413-32.374h5.654q-.515,24.667-.513,28.261a12.872,12.872,0,0,0,1.952,7.454q1.949,2.823,7.4,4.367T130.7,89.341q10.483,0,15.929-1.541t7.4-4.419a13.131,13.131,0,0,0,1.952-7.5q0-11.715-.515-28.159h5.552q-.209,3.906-.309,14.388t-.1,20.66l.1,10.995a34.112,34.112,0,0,1-2.52,14.079,20.17,20.17,0,0,1-9.3,9.712Q142.21,121.2,130.8,121.2T112.663,117.451Z" transform="translate(118.116 40.063)" fill="#007b56"/>
                  </g>
                </g>
              </svg>
            </div>
            <p>
            Un tofu fumé avec soin, à partir de soya biologique québécois. Peu transformé, pratique et savoureux, Tofutofu incarne une cuisine saine et moderne.
            </p>
            <Link to='/produits' className="button green">Voir nos produits</Link>
          </div>

          <div ref={el => (persos = el)} className={styles.persos}>
            <StaticImage
              className={state.isActive1 ? `${styles.perso} active` : `${styles.perso}` }
              src="../../static/images/tofu-persos-2.png"
              alt={sliderData[0].name}
              placeholder="blurred"
            />
            <StaticImage
              className={state.isActive2 ? `${styles.perso} ${styles.perso2} active` : `${styles.perso} ${styles.perso2}`}
              src="../../static/images/tofu-persos-2.png"
              alt={sliderData[1].name}
              placeholder="blurred"
            />
            <StaticImage
              className={state.isActive3 ? `${styles.perso} ${styles.perso3} active` : `${styles.perso} ${styles.perso3}`}
              src="../../static/images/tofu-persos-3.png"
              alt={sliderData[2].name}
              placeholder="blurred"
            />
          </div>

          <div ref={el => (images = el)} className={`main-images ${styles.images}`}>
            <StaticImage
              className={state.isActive1 ? `${styles.image} active` : `${styles.image}` }
              src="../../static/images/SAVEUR DU CAPITAINE.jpg"
              alt={sliderData[0].name}
            />
            <StaticImage
              className={state.isActive2 ? `${styles.image} ${styles.image2} active` : `${styles.image} ${styles.image2}`}
              src="../../static/images/SAVEUR ROTISSERIE.jpg"
              alt={sliderData[1].name}
            />
            <StaticImage
              className={state.isActive3 ? `${styles.image} ${styles.image3} active` : `${styles.image} ${styles.image3}`}
              src="../../static/images/SAVEUR SMOKED MEAT.jpg"
              alt={sliderData[2].name}
            />
          </div>
        </div>

        <div onClick={slideDown} className={`${styles.arrows} ${styles.down}`}>
          <img src={arrowImg} alt="down arrow" />
        </div>

      </div>
    </div>
  );
};

export default Hero
