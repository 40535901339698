import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './instagram.module.css'

const Instagram = () => {

  const [insta, setInsta] = useState([]);
  useEffect(() => {
    if (!window.sessionStorage.getItem('instagram')) {
      fetch('/api/instagram')
        .then((data) => data.json())
        .then((data) => {
          let photosArray = data.data;
          photosArray = photosArray.filter(photo => photo.media_type !== 'VIDEO')
          setInsta(photosArray);
          window.sessionStorage.setItem("instagram", JSON.stringify(photosArray));
        });
    } else {
      setInsta(JSON.parse(window.sessionStorage.getItem('instagram')));
    }
  }, []);

  let width = 0;
  try {
    width = window.innerWidth;
  } catch (e) { }

  return (
    <div className={styles.instagram}>
      <div className={styles.container}>
        <h2>Suivez-nous sur les réseaux sociaux</h2>
        <p><a target="_blank" rel="noreferrer noopener" href='https://www.instagram.com/tofutofu.ca/'>@tofutofu.ca</a></p>
        <ul className={`${styles.postListFirstRow} `}>
          <li>
            <a target="_blank" rel="noreferrer noopener" className={styles.link} href="https://www.instagram.com/tofutofu.ca/p/CrixG7rNrpU/">
              <StaticImage alt="Photo by Tofutofu on April 27, 2023." src="../../static/images/instagram/480589337_1034190428737347_510735969162387807_n.jpg" />
            </a>
          </li>

          <li><a target="_blank" rel="noreferrer noopener" className={styles.link} href="https://www.instagram.com/tofutofu.ca/reel/Cq9D6i6Aom3/">
            <StaticImage src="../../static/images/instagram/340679609_159562503707214_548550031676102179_n.jpg" />
          </a>
          </li>

          <li><a target="_blank" rel="noreferrer noopener" className={styles.link} href="https://www.instagram.com/tofutofu.ca/p/DHqtybOPwKk/">
            <StaticImage alt="Photo by Tofutofu on March 26, 2025. May be an image of tofu and text." src="../../static/images/instagram/486618005_1061116232711433_2069463024452333169_n.jpg" />
          </a>
          </li>

          <li><a target="_blank" rel="noreferrer noopener" className={styles.link} href="https://www.instagram.com/tofutofu.ca/p/DGn4ssstTbm/">
            <StaticImage alt="Photo by Tofutofu on February 28, 2025. May be an image of tofu and text that says 'TOFU TOFU TOFU Coop du CoopduC Cap'." src="../../static/images/instagram/487302453_1063403159149407_3379200923832487052_n.jpg" />
          </a>
          </li>

          <li><a target="_blank" rel="noreferrer noopener" className={styles.link} href="https://www.instagram.com/tofutofu.ca/p/DF5SLYztHoc/">
            <StaticImage alt="Photo by Tofutofu on February 10, 2025. May be an image of text." src="../../static/images/instagram/484561710_1052707106885679_9101856258979473079_n.jpg" />
          </a>
          </li>
        </ul>
        
      </div>
    </div>
  )
}

export default Instagram
